import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Navbar/footer';
import { BrowserRouter as Router, Routes, Route}
	from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Architecture from './pages/architecture';

import Bim from './pages/bim';
import YBO from './pages/ybo';
import SignUp from './pages/automation';
import Contact from './pages/contact';
import Solutions from './pages/solutions';

function App() {
return (
  <div>
	<Router>
	<Navbar />
	<Routes>
		<Route exact path='/home'  element={<Home />} />
		<Route path='/about' element={<About/>} />
		<Route path='/contact' element={<Contact/>} />
		<Route path='/architecture' element={<Architecture/>} />
		<Route path='/automation' element={<SignUp/>} />
		<Route path='/ybo' element={<YBO/>} />
    <Route path='/solutions' element={<Solutions/>} />

    <Route path='/bim' element={<Bim/>} />
	</Routes>
	<Footer />
	</Router>
 </div>
);
}

export default App;
