import React from 'react';


const architecture = () => {
return (
	<div id="solution">
   
<section>
                <div class="relative items-center w-full px-5 py-9 mx-auto md:px-12 lg:px-16 max-w-7xl">
                  <div class="w-full mx-auto text-left">
                    <div class="relative flex-col items-center m-auto align-middle">
                      <div class="items-center gap-12 text-left lg:gap-24 lg:inline-flex">
                        <div class="order-first block w-full mt-12 aspect-square lg:mt-0">
                          <img class="h-full" alt="hero" src={require("./arc.png")} />
                        </div>
                        <div class="flex flex-col mt-6 lg:mt-0">
                          <div class="max-w-xl">
                            <div>
                              <p class="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                               Architectural Services
                              </p>
                            </div>
                          </div>
                          <div class="mx-auto mt-6 lg:max-w-7xl">
                            <ul role="list" class="grid grid-cols-2 gap-4 list-none lg:grid-cols-1 lg:gap-3">
                              <li>
                                <div>
                                  <p class="mt-5 text-lg font-medium leading-6 text-black">
                                    Interior Design
                                  </p>
                                </div>
                                <div class="mt-2 text-base text-gray-500">
                                  Shops
                                  Communities
                                  Apartments
                                  Commercial Interiors
                                  wood working
                                  Exquisite interiors
                                </div>
                              </li>
                              <li>
                                <div>
                                  <p class="mt-5 text-lg font-medium leading-6 text-black">
                                  Architecture
                                  </p>
                                </div>
                                <div class="mt-2 text-base text-gray-500">
                                Warehouse
                                Storage Building
                                Landscape-architecture
                                </div>
                              </li>
                              <li>
                                <div>
                                  <p class="mt-5 text-lg font-medium leading-6 text-black">
                                 Tools
                                  </p>
                                </div>
                                <div class="mt-2 text-base text-gray-500">
                                  Plus, our platform is constantly evolving to meet the
                                  changing needs of the tech industry, ensuring you'll always
                                  be ahead.
                                </div>
                              </li>
                             
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mx-auto">
                            <div class="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-6 lg:mt-8">
                              <div class="flex justify-center col-span-1 px-8">
                                <img class="max-h-13" src="https://www.sketchup.com/themes/sketchup_www_terra/images/SketchUp-Horizontal-RGB.svg" alt="logo" />
                              </div>
                              <div class="flex justify-center col-span-1 px-8">
                                <img class="max-h-13" src={require("./vsk.png")}  alt="logo" />
                              </div>
                              <div class="flex justify-center col-span-1 px-8">
                                <img class="max-h-13" src={require("./p2.png")}  alt="logo" />
                              </div>
                              <div class="flex justify-center col-span-1 px-8">
                                <img class="max-h-13" src={require("./vrevit.png")}  alt="logo" />
                                </div>
                              <div class="flex justify-center col-span-1 px-8">
                              <img class="max-h-13" src={require("./v3d.png")}  alt="logo" />
                                </div>
                              <div class="flex justify-center col-span-1 px-8">
                              <img class="max-h-13" src={require("./rhino.png")}  alt="logo" />  </div>
                            </div>
                          </div>
              </section>
            
<section class="flex items-center w-full bg-white">
                <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-20 max-w-7xl">
              
                      <div class="grid grid-cols-2 gap-6 py-12 md:grid-cols-2 lg:grid-cols-4">
                        <figure>
                          <img class="w-full bg-gray-200 " src="https://www.sketchup.com/sites/default/files/molecule_image/SU2021-Web-Industry-Construction-S2.jpg" alt="" width="1310" height="873" />
              
                          <p class="mt-5 text-lg font-medium leading-6 text-black">
                          Less iteration, more building
                          </p>
                          <p class="mt-3 text-base text-gray-500">
                          Simplify the build by thinking through and communicating your projects in 3D 
                          </p>
                          <div class="flex gap-3 mt-10 justify-left">
                            <a class="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600" href="#">
                              <span>
                                Read more
                              </span>
                              <ion-icon class="flex-none w-3 h-3 ml-3 fill-blue-600 group-active:fill-current md hydrated" name="arrow-forward-outline" role="img" aria-label="arrow forward outline"></ion-icon>
                            </a>            </div>
                        </figure>
                        <figure>
                          <img class="w-full bg-gray-200 " src="https://www.sketchup.com/sites/default/files/molecule_image/SU2022-Web-Industry-Architecture-S3.jpg" alt="" width="1310" height="873" />
              
                          <p class="mt-5 text-lg font-medium leading-6 text-black">
                            Create Documents
                          </p>
                          <p class="mt-3 text-base text-gray-500">
                          Draw plans, elevations, sections, build details, and finish it off with your project before it’s built.
                          </p>
                          <div class="flex gap-3 mt-10 justify-left">
                            <a class="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600" href="#">
                              <span>
                                Read more
                              </span>
                              <ion-icon class="flex-none w-3 h-3 ml-3 fill-blue-600 group-active:fill-current md hydrated" name="arrow-forward-outline" role="img" aria-label="arrow forward outline"></ion-icon>
                            </a>            </div>
                        </figure>
                        <figure>
                          <img class="w-full bg-gray-200 " src="https://www.sketchup.com/sites/default/files/molecule_image/SU2021-Web-Industry-LandArch-S2.jpg" alt="" width="1310" height="873" />
              
                          <p class="mt-5 text-lg font-medium leading-6 text-black">
                         Work Fast
                          </p>
                          <p class="mt-3 text-base text-gray-500">
                          Technology that’s fast, robust, accurate, and interoperable to Design better. Model with ease.
                          </p>
                          <div class="flex gap-3 mt-10 justify-left">
                            <a class="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600" href="#">
                              <span>
                                Read more
                              </span>
                              <ion-icon class="flex-none w-3 h-3 ml-3 fill-blue-600 group-active:fill-current md hydrated" name="arrow-forward-outline" role="img" aria-label="arrow forward outline"></ion-icon>
                            </a>            </div>
                        </figure>
                        <figure>
                          <img class="w-full bg-gray-200 " src="https://www.sketchup.com/sites/default/files/molecule_image/SU2022-Web-Industry-Architecture-S4.jpg" alt="" width="1310" height="873" />
              
                          <p class="mt-5 text-lg font-medium leading-6 text-black">
                         Renderings
                          </p>
                          <p class="mt-3 text-base text-gray-500">
                          From detailed line drawings to rich, colorful images, you can leverage your existing models into assets.
                          </p>
                          <div class="flex gap-3 mt-10 justify-left">
                            <a class="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600" href="#">
                              <span>
                                Read more
                              </span>
                              <ion-icon class="flex-none w-3 h-3 ml-3 fill-blue-600 group-active:fill-current md hydrated" name="arrow-forward-outline" role="img" aria-label="arrow forward outline"></ion-icon>
                            </a>            </div>
                        </figure>
                      </div>
              
                </div>
              </section>
             {/*
    <div class="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-sky-500">
        <path fillRule="evenodd" d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z" clipRule="evenodd"></path>
        <path fillRule="evenodd" d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z" clipRule="evenodd"></path>
      </svg><div class="space-y-6 justify-between text-gray-600 md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center">
        <div class="md:5/12 lg:w-1/2">
          <img src="./images/pie.svg" alt="image" loading="lazy" width="" height="" class="w-full" />
        </div>
        <div class="md:7/12 lg:w-1/2">
          <h2 class="text-3xl font-bold text-gray-900 md:text-4xl dark:text-white">
		  Areas of activity
          </h2>
          <p class="my-8 text-gray-600 dark:text-gray-300">
		  Power Energy,Public utility objects,Industrial objects,Automotive industry </p>
          <div class="divide-y space-y-4 divide-gray-100 dark:divide-gray-800">
            <div class="mt-8 flex gap-4 md:items-center">
              <div class="w-12 h-12 flex gap-4 rounded-full bg-indigo-100 dark:bg-indigo-900/20">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 m-auto text-indigo-500 dark:text-indigo-400">
                  <path fillRule="evenodd" d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z" clipRule="evenodd"></path>
                </svg>        
              </div>
              <div class="w-5/6">
                <h3 class="font-semibold text-lg text-gray-700 dark:text-indigo-300">Chat Anytime</h3>
                <p class="text-gray-500 dark:text-gray-400">Asperiores nemo possimus nesciunt quam mollitia.</p>
              </div> 
            </div> 
            <div class="pt-4 flex gap-4 md:items-center">
              <div class="w-12 h-12 flex gap-4 rounded-full bg-teal-100 dark:bg-teal-900/20">  
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 m-auto text-teal-600 dark:text-teal-400">
                  <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>
                </svg>                                      
              </div>
              <div class="w-5/6">
                <h3 class="font-semibold text-lg text-gray-700 dark:text-teal-300">Real Time Location</h3>
                <p class="text-gray-500 dark:text-gray-400">Asperiores nemo possimus nesciunt quam mollitia.</p>
              </div> 
            </div> 
          </div>
        </div>
      </div>
</div>
<div class="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
    <div>
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Technical Specifications</h2>
      <p class="mt-4 text-gray-500">finding the common componets in the structrues and making them as re usable component.</p>

      <dl class="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Team</dt>
          <dd class="mt-2 text-sm text-gray-500">Structural design engineers, detailers, fabricators, contractors, and project managers can create, combine, manage and share accurate models for every project..</dd>
        </div>

        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Delivery</dt>
          <dd class="mt-2 text-sm text-gray-500">Improve design quality with better access to source model data and share drawings safely. An open collaboration tool ensures the highest level of security through best practices and compliance with industry-standard certifications.</dd>
        </div>

        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Innovation</dt>
          <dd class="mt-2 text-sm text-gray-500">we can help you transform the construction industry, overcome challenges and achieve outstanding results with our innovative ideas.</dd>
        </div>

        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Finish</dt>
          <dd class="mt-2 text-sm text-gray-500">Hand sanded and finished with natural oil</dd>
        </div>

        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Quality</dt>
          <dd class="mt-2 text-sm text-gray-500">Quality </dd>
        </div>

        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Considerations</dt>
          <dd class="mt-2 text-sm text-gray-500">analysis, diverse applications of use, interoperability, and time-saving capabilities.</dd>
        </div>
      </dl>
    </div>
    <div class="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
      <img src="https://www.tekla.com/assets/styles/16_9_288x162/s3/2021-06/Product-Teaser-Tekla-Structures-288x162.jpg" alt="Walnut card tray with white powder coated steel divider and 3 punchout holes." class="rounded-lg bg-gray-100" />
      <img src="https://www.tekla.com/assets/styles/16_9_288x162/s3/2021-06/Product-Teaser-Tekla-Structural-Designer-288x162.jpg" alt="Top down view of walnut card tray with embedded magnets and card groove." class="rounded-lg bg-gray-100" />
      <img src="https://www.tekla.com/assets/styles/16_9_288x162/s3/2021-06/Product-Teaser-Tekla-Tedds-288x162.jpg" alt="Side of walnut card tray with card groove and recessed card area." class="rounded-lg bg-gray-100" />
      <img src="https://www.tekla.com/assets/styles/16_9_288x162/s3/2021-06/Product-Teaser-Trimble-Connect-288x162.jpg" alt="Walnut card tray filled with cards and card angled in dedicated groove." class="rounded-lg bg-gray-100" />
    </div>
  </div>

  <div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
  <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center" />
  <svg viewBox="0 0 1097 845" aria-hidden="true" class="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]">
    <path fill="url(#10724532-9d81-43d2-bb94-866e98dd6e42)" fillOpacity=".2" d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z" />
    <defs>
      <linearGradient id="10724532-9d81-43d2-bb94-866e98dd6e42" x1="1097.04" x2="-141.165" y1=".22" y2="363.075" gradientUnits="userSpaceOnUse">
        <stop stopColor="#776FFF" />
        <stop offset="1" stopColor="#FF4694" />
      </linearGradient>
    </defs>
  </svg>
  <svg viewBox="0 0 1097 845" aria-hidden="true" class="absolute left-1/2 -top-52 -z-10 w-[68.5625rem] -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0">
    <path fill="url(#8ddc7edb-8983-4cd7-bccb-79ad21097d70)" fillOpacity=".2" d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z" />
    <defs>
      <linearGradient id="8ddc7edb-8983-4cd7-bccb-79ad21097d70" x1="1097.04" x2="-141.165" y1=".22" y2="363.075" gradientUnits="userSpaceOnUse">
        <stop stopColor="#776FFF" />
        <stop offset="1" stopColor="#FF4694" />
      </linearGradient>
    </defs>
  </svg>
  
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:mx-0">
      <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Work with us</h2>
      <p class="mt-6 text-lg leading-8 text-gray-300">We help clients maximize efficiency, productivity and cost-savings by providing best-in-class solutions powered by cutting-edge technology in the areas of Structural Steel Detailing, Connection Design, Building Information Modeling and Architectural Services.
      The number of happy customers across the globe is the certificate of judging our success rate.

We have a highly skilled technical team, which includes engineers, modelers, editors and checkers. Our team has strong experience in a range of software, namely, Tekla, SDS/2, AutoCAD, MBS, STAAD and more and have delivered projects to clients globally using AISC, NISD, CISC standards.

Our clients use our facility as an extension to their shop. We are one stop shop for a large number of our clients. Having large size technical project team’s expert in Tekla and AutoCAD enables us to serve multiple large size projects at once. We have been a go-to company for hundreds of fabricators over the period of our existence. Our resources are well-versed in AISC, BS, MBMA, ASD, AISI, AWS, MBCI, and LRFD standards and practices.




.</p>
    </div>
    <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
      <div class="grid grid-cols-1 gap-y-6 gap-x-8 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
        <a href="#">Structural Projects <span aria-hidden="true">&rarr;</span></a>

        <a href="#">Internship program <span aria-hidden="true">&rarr;</span></a>

        <a href="#">Our values <span aria-hidden="true">&rarr;</span></a>

        <a href="#">Meet our leadership <span aria-hidden="true">&rarr;</span></a>
      </div>
      <dl class="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
        <div class="flex flex-col-reverse">
          <dt class="text-base leading-7 text-gray-300">Offices worldwide</dt>
          <dd class="text-2xl font-bold leading-9 tracking-tight text-white">12</dd>
        </div>

        <div class="flex flex-col-reverse">
          <dt class="text-base leading-7 text-gray-300">Full-time colleagues</dt>
          <dd class="text-2xl font-bold leading-9 tracking-tight text-white">300+</dd>
        </div>

        <div class="flex flex-col-reverse">
          <dt class="text-base leading-7 text-gray-300">Hours per week</dt>
          <dd class="text-2xl font-bold leading-9 tracking-tight text-white">40</dd>
        </div>

        <div class="flex flex-col-reverse">
          <dt class="text-base leading-7 text-gray-300">Paid time off</dt>
          <dd class="text-2xl font-bold leading-9 tracking-tight text-white">Unlimited</dd>
        </div>
      </dl>
    </div>
  </div>
</div>

*/}
            
</div>

);
};

export default architecture;
