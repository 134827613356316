import React, { useState } from "react";

import img1 from "../assets/images/st1.jpg";
import img2 from "../assets/images/st2.jpg";
import img3 from "../assets/images/st3.jpg";


import ProjectCard from "./ProjectCard";

function Solutions() {
  const [durum, setDurum] = useState(1);

  const projects = [
    {
      id: 1,
      title: "Business Startup",
      description: "Design & Development",
      img: img1,
    },
    {
      id: 2,
      title: "Business Startup",
      description: "Design & Development",
      img: img2,
    },
    {
      id: 3,
      title: "Business Startup",
      description: "Design & Development",
      img: img3,
    },
    {
      id: 4,
      title: "Business Startup",
      description: "Design & Development",
      img: img2,
    },
    {
      id: 5,
      title: "Business Startup",
      description: "Design & Development",
      img: img3,
    },
    {
      id: 6,
      title: "Business Startup",
      description: "Design & Development",
      img: img1,
    },
  ];

  return (
    <>
      <div id="projects" className="projects  text-white py-10">

      <section>
                <div class="items-center w-full px-5 py-24 mx-auto md:px-12 lg:px-16 max-w-7xl">
                  <div class="text-left">
                    <div class="items-center mx-auto lg:inline-flex">
                      <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-24">
                        <div>
                          <p class="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                            Our Projects
                          </p>
                        </div>
                        <div class="lg:ml-auto">
                          <p class="mt-4 text-lg tracking-tight text-gray-600 lg:mt-0">
                            Whether you're a beginner or an experienced, our 
                            product offers the tools you need to be successful.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="relative items-center w-full mx-auto mt-12">
                    <img class="object-cover object-center w-full bg-gray-300 h-96"  src={require("./prbg2.jpg")} />
                  </div>
                  <div>
                    <div class="pt-12 mx-auto lg:max-w-7xl">
                      <dl class="grid grid-cols-1 gap-6 space-y-0 lg:gap-24 lg:grid-cols-3">
                        <div>
                          <div>
                            <p class="text-lg font-medium leading-6 text-black">
                              Developer experience
                            </p>
                          </div>
                          <div class="mt-2 text-base text-gray-500">
                            Plus, our platform is constantly evolving to meet the changing
                            needs of the tech industry, ensuring you'll always be ahead.
                          </div>
                        </div>
                        <div>
                          <div>
                            <p class="text-lg font-medium leading-6 text-black">
                              Designers go-to app
                            </p>
                          </div>
                          <div class="mt-2 text-base text-gray-500">
                            Plus, our platform is constantly evolving to meet the changing
                            needs of the tech industry, ensuring you'll always be ahead.
                          </div>
                        </div>
                        <div>
                          <div>
                            <p class="text-lg font-medium leading-6 text-black">
                              Easy onboarding
                            </p>
                          </div>
                          <div class="mt-2 text-base text-gray-500">
                            Plus, our platform is constantly evolving to meet the changing
                            needs of the tech industry, ensuring you'll always be ahead.
                          </div>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </section>
            
      
        <div className="flex justify-center items-center gap-4 mt-12 mb-2 ">
          <button
            onClick={() => setDurum(1)}
            className={`inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 focus:ring-offset-gray-800 ${
               ""
            }`}
          >
           Industrial Projects
          </button>
          <button
            onClick={() => setDurum(2)}
            className={`inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 focus:ring-offset-gray-800 ${
              ""
            }  `}
          >
            Commercial Projects
          </button>
          <button
            onClick={() => setDurum(3)}
            className={`font-bold text-[19px] border-2  bg-[#171717] rounded-[6px] p-[4px]  ${
              durum === 3 ? "bg-[linear-gradient(90deg,#b004b0,#38097a)]" : ""
            }`}
          >
             Architectural Projects
          </button>
        </div>
        <div className="grid grid-cols-4 p-3 justify-center items-center gap-8 ">
          {durum === 1
            ? projects.map((item, i) => <ProjectCard key={i} item={item} />)
            : null}
        </div>
        {durum === 2 ? (
          <div
            id="text2"
            className="tab-pane mt-3 text-lg text-gray-800 text-gray-400 "
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
            atque porro quasi dolorum facere tempore maxime nemo quia nulla
            blanditiis doloribus, dolore, voluptas aspernatur harum facilis
            cumque magni soluta sapiente.
          </div>
        ) : null}
        {durum === 3 ? (
          <div
            id="text1"
            className="tab-pane  mt-3 text-lg text-gray-800 text-gray-400"
          >
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum, nisi.
          </div>
        ) : null}
      </div>
      
    </>
  );
}

export default Solutions;